
/**
 * ppga vaulting 选中态
 */
export const PPGA_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

/**
 * afterpay vaulting 选中态
 */
export const AFTER_PAY_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}
