<template>
  <div class="bin-coupons-block">
    <div
      v-if="isSupportBin"
      v-expose="{
        id: '1-11-1-212',
        data: binCouponType
      }"
      class="pay-bin-coupons"
      @click="goCouponPage"
    >
      <span class="txt txt-discount">
        {{ discountText }}
      </span>
      <span
        v-if="normalPayData.showCard"
        class="txt txt-small txt-normal-pay"
      >
        {{ normalPayData.cardName }}
      </span>
      <span
        v-if="!isUseCoupon"
        class="txt"
        :class="{ 'txt-small': normalPayData.showCard }"
      >
        &gt;
      </span>
    </div>
    <span
      v-if="isSupportBin && isPayBinCouponsAdd"
      class="add-tip"
    >
      +
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useCouponsDiscount } from './useCouponsDiscount.js'
import { template } from '@shein/common-function'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  cardBin: {
    type: String,
    default: ''
  },
  isPayBinCouponsAdd: {
    type: [Boolean, Number],
    default: false
  },
  isShowTokenPayment: {
    type: Boolean,
    default: false
  }
})

const store = useStore()

const nowSelectBin = computed(() => {
  return (
    store?.state?.handlerSelectBin ||
    store?.state?.defaultSelectTokenPay?.card_bin ||
    ''
  )
})

const usableCouponList = computed(() => {
  return store?.state?.usableCouponList
})

const coupon = computed(() => {
  return store?.state?.coupon
})

const supportBinData = computed(() => {
  if (props.isShowTokenPayment) {
    const allCanUseUsableCouponList =
      usableCouponList.value?.filter(item =>
        item?.card?.card_codes?.includes(props.cardBin)
      ) || []

    const nowUseCoupon = allCanUseUsableCouponList?.find(item =>
      coupon.value?.applyCoupons?.includes(item?.coupon)
    )

    // 已若卡bin优惠券券已被使用（计算价格使用了券码），则正常下发展示使用成功的卡bin优惠券信息
    if (nowUseCoupon) {
      return nowUseCoupon
    }

    // 若存在交集的券数量＞1，则优先取接口返回的第一个券
    return allCanUseUsableCouponList?.[0]
  } else {
    const nowUseCoupon = usableCouponList.value?.find(item => {
      return (
        coupon.value?.applyCoupons?.includes(item?.coupon) &&
        item?.card?.card_name
      )
    })
    if (nowUseCoupon) {
      return nowUseCoupon
    }
    // 非token支付情况下，选择第一个有cardName的券
    const curCoupon = usableCouponList.value?.find(
      item => item?.card?.card_name
    )
    return curCoupon
  }
})

const isSupportBin = computed(() => {
  return !!supportBinData.value
})

const discount = computed(() => {
  const couponTypeId = supportBinData.value?.coupon_type_id

  const { getDiscount, getCurrentCouponRule } = useCouponsDiscount(
    props.language
  )

  return getDiscount(couponTypeId, getCurrentCouponRule(supportBinData.value))
})

const isSelectToken = computed(() => {
  return props.cardBin === nowSelectBin.value
})

const isUseCoupon = computed(() => {
  let isOkInToken = true
  if (props.isShowTokenPayment) {
    isOkInToken = isSelectToken.value
  }
  return (
    isOkInToken &&
    !!coupon.value &&
    !!supportBinData.value &&
    coupon.value?.applyCoupons?.includes(supportBinData.value?.coupon)
  )
})

const discountText = computed(() => {
  return isUseCoupon.value
    ? `${template(discount.value, props.language.SHEIN_KEY_PC_31098)}`
    : `${template(discount.value, props.language.SHEIN_KEY_PC_31097)}`
})
const isInDrawerList = computed(() => {
  // return isShowBinCouponsDrawerInNormalPay.value
  return store?.state?.checkout.isShowBinCouponsDrawerInNormalPay
})
// 在非token支付的情况下，是否展示card 和  cardName取值
const normalPayData = computed(() => {
  let data = {
    showCard: false,
    cardName: ''
  }

  if (!props.isShowTokenPayment && supportBinData.value) {
    data.showCard = true
    data.cardName = supportBinData.value?.card?.card_name
  }
  return data
})

const binCouponType = computed(() => {
  return {
    bincoupon_type: isUseCoupon.value ? 1 : 0
  }
})

const goCouponPage = () => {
  daEventCenter.triggerNotice({
    daId: '1-11-1-213',
    extraData: {
      ...binCouponType.value
    }
  })
  if (isInDrawerList.value) return // 非token切换支付方式抽屉展示时，点击不在支持跳转券列表
  if (isUseCoupon.value) return // 用券后, 点击不在支持跳转券列表
  store.commit('showCouponListAndAnchor', supportBinData.value?.coupon)

  store.commit('changeParamsStatus', {
    type: 'coupon',
    params: {
      showCouponList: true
    }
  })
}
</script>

<style scoped lang="less">
.bin-coupons-block {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  .pay-bin-coupons {
    display: flex;
    align-items: center;
    margin-bottom: unset;
    border: 1px #edc2aa solid;
    border-radius: 1px;
    background-color: #fff6f3;
    padding: 1px 6px;
    position: relative;
    color: #c44a01;
    font-size: 12px;
    font-family: SF UI Text;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 16px;
    max-width: 100%;

    // 超出一行展示省略号
    .txt {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .txt-discount {
      margin-right: 4px;
    }
    .txt-small {
      font-weight: 400;
    }
    .txt-normal-pay {
      position: relative;
      padding-left: 4px;
      margin-right: 4px;
      &::before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 15%;
        left: 0;
        width: 1/75rem;
        height: 70%;
        border-left: 1/75rem solid var(---sui_color_discount_dark, #c44a01);
      }
    }
  }

  .add-tip {
    margin: 0 4px;
  }
}

.coupons-before-after() {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 50%;
  width: 4px;
  height: 8px;
  border: 1px solid #edc2aa;
  transform: translateY(-50%);
  background-color: white;
}

.pay-bin-coupons::before {
  .coupons-before-after();
  left: -1px;
  border-left-color: #fff;
  border-radius: 0 4px 4px 0;
}

.pay-bin-coupons::after {
  .coupons-before-after();
  right: -1px;
  border-right-color: #fff;
  border-radius: 4px 0 0 4px;
}
</style>
