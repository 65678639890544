<template>
  <p
    v-if="isShowDiscountTips || isShowTokenBinDiscountBlock || isSupportBin"
    class="discount-percent_tips dicount-flex-sy"
  >
    <!-- 卡bin优惠券 -->
    <pay-bin-coupons
      v-if="item.code === 'routepay-card'"
      :card-bin="cardBin"
      :is-pay-bin-coupons-add="isPayBinCouponsAdd"
      :is-show-token-payment="isShowTokenPayment"
      :language="language"
    />

    <!-- token 卡 bin 优惠 -->
    <template v-if="isShowTokenBinDiscountBlock && isNoShowTokenBinRandomDiscount">
      <span class="discount-tips-item">
        {{ tokenBinDiscountDesc }}
      </span>
      <span
        v-if="isHadShowOnlinePayDiscount || isShowRandomDiscount"
        class="add-icon"
      >
        +
      </span>
    </template>

    <span
      v-if="isShowRandomDiscount"
      class="discount-tips-item"
    >
      <span>
        {{ randomDiscountDes(item) }}
      </span>
    </span>
    <template v-if="isHadShowOnlinePayDiscount">
      <span
        v-if="
          getOnlinePayDiscountValue.min &&
            +getOnlinePayDiscountValue.min.amount != 0
        "
        class="discount-tips-item"
      >
        <!-- 满减优惠 -->
        <template
          v-if="
            getOnlinePayDiscountValue &&
              getOnlinePayDiscountValue.discountType == 0
          "
        >
          {{
            template(
              mallCaculateInfo.onlinePayDiscountInfo.onlinePayDiscountValue[
                item.code
              ].min.amountWithSymbol,
              mallCaculateInfo.onlinePayDiscountInfo.onlinePayDiscountValue[
                item.code
              ].amount.amountWithSymbol,
              language.SHEIN_KEY_PC_16461
            )
          }}
        </template>

        <!-- 折扣优惠 -->
        <template
          v-else-if="
            mallCaculateInfo.onlinePayDiscountInfo.onlinePayDiscountValue[
              item.code
            ].discountType == 1
          "
        >
          <span>
            {{
              template(
                mallCaculateInfo.onlinePayDiscountInfo.onlinePayDiscountValue[
                  item.code
                ].min.amountWithSymbol,
                mallCaculateInfo.onlinePayDiscountInfo.onlinePayDiscountValue[
                  item.code
                ].percent + '%OFF',
                language.SHEIN_KEY_PC_16461
              )
            }}.
          </span>
          <span
            v-if="
              mallCaculateInfo.onlinePayDiscountInfo.onlinePayDiscountValue[
                item.code
              ].limit_value &&
                +mallCaculateInfo.onlinePayDiscountInfo.onlinePayDiscountValue[
                  item.code
                ].limit_value.amount != 0
            "
          >
            {{
              language.SHEIN_KEY_PC_20465.replace(
                '{limitValue}',
                mallCaculateInfo.onlinePayDiscountInfo.onlinePayDiscountValue[
                  item.code
                ].limit_value.amountWithSymbol
              )
            }}
          </span>
        </template>
      </span>
      <span
        v-else-if="
          getOnlinePayDiscountValue &&
            getOnlinePayDiscountValue.discountType == 1 &&
            getOnlinePayDiscountValue.limit_value &&
            +getOnlinePayDiscountValue.limit_value.amount != 0
        "
        class="discount-tips-item"
      >
        {{
          language.SHEIN_KEY_PC_20465.replace(
            '{limitValue}',
            mallCaculateInfo.onlinePayDiscountInfo.onlinePayDiscountValue[
              item.code
            ].limit_value.amountWithSymbol
          )
        }}
      </span>
    </template>

    <template v-if="!isShowTokenPayment && !isShowTokenFrontInstallment">
      <span v-if="isShowOnlinePayDiscount && isShowCardBinDiscount">+</span>
      <span
        v-if="isShowCardBinDiscount"
        class="discount-tips-item card-bin-discount-tips"
      >
        <span>{{ cardBinDiscountDesc }}</span>
        <Icon
          class="doubt-icon"
          name="sui_icon_doubt_14px_1"
          size="15px"
          color="#666666"
          @click.stop="handleCardBinDiscountTips"
        />
      </span>
    </template>
  </p>
</template>

<script setup>
import { template } from '@shein/common-function'
import { ref, computed, watch } from 'vue'
import { useTokenBinDiscount } from './useTokenBinDiscount.js'
import PayBinCoupons from 'public/src/pages/checkout/vue_tpls/coupons/pay_bin_coupons.vue'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  checkout: {
    type: Object,
    default: () => {}
  },
  isShowDiscountTips: {
    type: [Boolean, Number],
    default: false
  },
  isHadShowOnlinePayDiscount: {
    type: Boolean,
    default: false
  },
  isShowRandomDiscount: {
    type: [Boolean, Number],
    default: false
  },
  randomDiscountDes: {
    type: Function
  },
  item: {
    type: Object,
    default: () => {}
  },
  getOnlinePayDiscountValue: {
    type: Object,
    default: () => {}
  },
  mallCaculateInfo: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  isShowTokenPayment: {
    type: Boolean,
    default: false
  },
  isShowOnlinePayDiscount: {
    type: Boolean,
    default: false
  },
  isShowCardBinDiscount: {
    type: [Boolean, Number],
    default: false
  },
  cardBinDiscountDesc: {
    type: String,
    default: ''
  },
  cardBin: {
    type: String,
    default: ''
  },
  cardBinDiscountInfo: {
    type: Object,
    default: () => {}
  },
  isSupportBin: {
    type: Boolean,
    default: false
  },
  isShowTokenFrontInstallment: {
    type: Boolean,
    default: false
  }
})

const { getCardBinDiscountDesc } = useTokenBinDiscount(
  props.cardBinDiscountInfo,
  props.cardBin,
  props.language
)

const tokenBinDiscountDesc = ref(getCardBinDiscountDesc())
const isTokenBinRandomType = ref(false)

const tokenBinDiscountFrontWebAbt = computed(() => {
  return props.checkout?.memberPreCardTokenInfo?.tokenBinDiscountFrontWebAbt
})

const isShowTokenBinDiscount = computed(() => {
  if (!tokenBinDiscountFrontWebAbt.value) {
    return false
  }

  if (props.isShowTokenPayment) {
    const { all_card_bin_discount_map = {} } = props.cardBinDiscountInfo || {}
    return props.cardBin in all_card_bin_discount_map
  }

  if (props.isShowTokenFrontInstallment) {
    const { all_card_bin_discount_map = {} } = props.cardBinDiscountInfo || {}
    return props.cardBin in all_card_bin_discount_map
  }

  return false
})

const isShowTokenBinDiscountBlock = computed(() => {
  return isShowTokenBinDiscount.value && tokenBinDiscountDesc.value?.length > 0
})

const isPayBinCouponsAdd = computed(() => {
  return (
    isShowTokenBinDiscountBlock.value ||
    props.isHadShowOnlinePayDiscount ||
    props.isShowRandomDiscount
  )
})

// 默认无选中支付方式，选择非卡支付（卡分期或卡路由），对应卡支付方式列表不同时展示在线随机立减和卡bin随机立减文案
const isNoShowTokenBinRandomDiscount = computed(() => {
  if (!isTokenBinRandomType.value) return true
  return isTokenBinRandomType.value && !props.isShowRandomDiscount
})

watch(
  () => props.cardBinDiscountInfo,
  () => {
    const { getCardBinDiscountDesc, isTokenBinRandomType } = useTokenBinDiscount(
      props.cardBinDiscountInfo,
      props.cardBin,
      props.language
    )
    tokenBinDiscountDesc.value = getCardBinDiscountDesc()
    isTokenBinRandomType.value = isTokenBinRandomType
  },
  {
    immediate: true,
    deep: true
  }
)

watch(
  () => props.cardBin,
  () => {
    const { getCardBinDiscountDesc, isTokenBinRandomType } = useTokenBinDiscount(
      props.cardBinDiscountInfo,
      props.cardBin,
      props.language
    )
    tokenBinDiscountDesc.value = getCardBinDiscountDesc()
    isTokenBinRandomType.value = isTokenBinRandomType
  },
  {
    immediate: true,
    deep: true
  }
)
</script>

<style lang="less" scoped>
.add-icon {
  margin: 0 4px;
}
</style>
