import schttp from 'public/src/services/schttp'
import schttpSync from 'public/src/services/schttp/sync'
import newBffUtils from './utils'

const { currency, appLanguage } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

let versionV1 = ['checkout_v1']
// 查询支付域图标信息
export const fetchPayDomainLogoApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  console.log('isNewBff======>', isNewBff)
  if (isNewBff) {
    // node bff loginc, default payment_code is routepay-card
    if (!data.payment_code) {
      data.payment_code = 'routepay-card'
    }
    return await schttp({
      method: 'GET',
      url: `/order/get_payment_page_logo`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
  } else {
    return await schttp({
      url: '/api/checkout/payDomainLogo/get',
      params: data
    })
  }
}
// 获取ccc弹窗内容
export const fetchChemicalsIdsApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const res = await schttp({
      method: 'GET',
      url: `/ccc/p65/dialog/web`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        matchTagIds: JSON.stringify(data.matchTagIds)
      },
      useBffApi: true
    })
    res.data = []
    if (res?.code === '0' && res?.info?.dialogs) {
      res.isOk = true
      res.data = res.info.dialogs
    }
    return res
  } else {
    return await schttp({
      url: `/api/checkout/dialogIndexCon/get`,
      params: {
        matchTagIds: JSON.stringify(data.matchTagIds)
      }
    })
  }
}
//  更新未支付订单账单地址
export const fetchOrderBillingAddressApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    return await schttp({
      method: 'POST',
      url: `/order/update_order_info`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        billno: data.billno
      },
      data: data.orderInfo,
      useBffApi: true
    })
  } else {
    return await schttp({
      method: 'POST',
      url: '/api/checkout/orderBillingAddress/update',
      params: {
        billno: data.billno
      },
      data: data.orderInfo
    })
  }
}

// 校验店配地址
export const fetchCheckStoreAddressApi = params => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  
  if (isNewBff) {
    return schttpSync({
      method: 'POST',
      url: `/user/address/store/check`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        storeCountryId: params.data.countryId,
      },
      success: res => {
        if (res.code !== '0' && res?.msg) {
          res.tips = res.msg
        }
        params.success(res)
      },
      useBffApi: true
    })
  } else {
    return schttpSync({
      method: 'POST',
      url: '/api/checkout/checkStoreAddress/get',
      data: params.data,
      success: params.success
    })
  }
}

// 查询钱包交易记录数
export const fetchWalletInfoApi = async () => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    return await schttp({
      method: 'POST',
      url: '/user/wallet/freezing',
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      useBffApi: true
    })
  } else {
    return await schttp({
      url: '/api/checkout/walletInfo/get'
    })
  }
}

// ccc券列表公告
export const fetchCouponListNoticeApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const res = await schttp({
      method: 'GET',
      url: `/order/checkout/coupon/notice`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
    if (res?.code === '0' && res?.info?.content) {
      res.isOk = true
      res.data = res.info
    }
    return res
  } else {
    const res = await schttp({
      url: '/api/checkout/noticeCouponList/get',
      params: data
    })
    if (res?.code == '1000' && res.data) {
      res.isOk = true
    }
    return res
  }
}
